.spin-time-container {
    justify-content: center;
}

.spin-time-container h1 {
    font-size: 2.25em;
    text-align: center;
}

.spin-time-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spin-h1 {
    display: inline;
    margin: 0;
}

.spin-time-change {
    display: inline;
    margin-left: 5px;
    font-size: 1.5em;
    text-align: center;
}