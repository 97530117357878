 .artists-container h2 {
   text-align: center;
   font-size: 1.5em;
   color: #e0e0e0;
   margin-bottom: 5px;
   margin-top: 5px;
 }

 /* Playlist items */
 .artists-container ol {
   padding-inline-start: 20px;
 }

 .list-item {
   align-items: center;
   margin-bottom: 10px;
   color: #e0e0e0;
 }

 .artist-info p {
   margin: 0px;
 }

 .artist-image {
   width: 60px;
   height: 60px;
   margin-right: 10px;
   border-radius: 5px;
 }

 .artist-spins {
   display: flex;
   gap: 5px;
   /* optional spacing between the two <p> tags */
   align-items: center;
   /* optional vertical alignment */
 }

 .change {
   font-size: 0.8rem;
   /* or whatever size works for your design */
 }