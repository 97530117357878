.date-selector-container {
    display: flex;
    flex-wrap: wrap;
    /* ✅ allows children to wrap onto a new line */
    gap: 1rem;
}

.date-label {
    color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    /* optional: spacing when wrapped */
    flex: 1 1 200px;
    /* ✅ allows each label to shrink and wrap */
}

.date-input {
    margin-top: 0.25rem;
    padding: 0.5rem 0.75rem;
    background-color: #ccc;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
}