.profile-container {
    justify-content: center;
}

.profile-container h1 {

    font-size: 3em;
    text-align: center;
}

.profile-container p {
    font-size: 1.5em;
    /* text-align: center; */
}

.name {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0.25rem;
}

.followers,
.display-name {
    font-size: 1rem;
    margin: 0.25rem 0;
}