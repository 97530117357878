.genres-container h2 {
    text-align: center;
    font-size: 1.5em;
    color: #e0e0e0;
    margin-bottom: 5px;
    margin-top: 5px;
}

/* Playlist items */
.genres-container ol {
    padding-inline-start: 20px;
}

.list-item {
    align-items: center;
    margin-bottom: 10px;
    color: #e0e0e0;
}

.genre-name {

    font-size: 24px;
}

.genre-info p {
    margin: 0px;
}

.genre-image {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 5px;
}

.genre-spins {
    display: flex;
    gap: 5px;
    /* optional spacing between the two <p> tags */
    align-items: center;
    /* optional vertical alignment */
}

.change {
    font-size: 0.8rem;
    /* or whatever size works for your design */
}